import { IEstablishment } from './establishments.model'

export interface IAssociation {
  _id?: string
  name?: string
  establishments?: IEstablishment[]
  establishmentsNames?: string[]
  carrierName?: string
  carrierAddress?: string
  phone?: string
  email?: string
  coordinator?: string[],
  postalCode?: string,
  city?: string,
  createdAt?: Date
  updatedAt?: Date
}

export class Association implements IAssociation {
  constructor (
    public _id?: string,
    public name?: string,
    public establishments?: IEstablishment[],
    public createdAt?: Date,
    public updatedAt?: Date,
    public uuid?: string,
    public carrierName?: string,
    public carrierAddress?: string,
    public phone?: string,
    public email?: string,
    public coordinator?: string[],
    public postalCode?: string,
    public city?: string,
    public establishmentsNames?: string[]
  ) {
    this._id = _id
    // return this
  }
}
