import { IEstablishment } from './establishments.model'

export interface IEducationPassHolder {
  _id?: string
  establishment?: IEstablishment
  educationPassNumber?: string
  firstName?: string
  lastName?: string
  birthDate?: string
  email?: string
  phone?: string
  deRegister?: string
  consentOngoingContact?: boolean
  issueDate?: Date
  legalGuardians?: [
    {
      firstName?: string
      lastName?: string
    },
  ]
  premiumPayments?: [
    {
      age?: string
      paymentDate?: string
      amount?: number
      payedBy?: string
    },
  ]
  createdAt?: Date
  updatedAt?: Date
}

export class EducationPassHolder implements IEducationPassHolder {
  constructor (
    public _id?: string,
    public establishment?: IEstablishment,
    public educationPassNumber?: string,
    public firstName?: string,
    public lastName?: string,
    public birthDate?: '',
    public email?: string,
    public phone?: string,
    public consentOngoingContact?: boolean,
    public deRegister?: '',
    public issueDate?: Date,
    public legalGuardians?: [
      {
        firstName?: ''
        lastName?: ''
      },
    ],
    public premiumPayments?: [
      {
        age?: ''
        paymentDate?: undefined
        amount?: 0
        payedBy?: ''
      },
    ]
  ) {
    this._id = _id
    // return this
  }
}
