export interface ILectureContent {
  _id?: string
  title?: string,
  image?: {
    imageContentType: string,
    image: string
  },
  content?: string
  published?: boolean
  createdAt?: Date
  updatedAt?: Date
}

export class LectureContent implements ILectureContent {
  constructor (
    public _id?: string,
    public title?: string,
    public image?: {
      imageContentType: string,
      image: string
    },
    public content?: string,
    public published?: boolean
  ) {
    this._id = _id
    // return this
  }
}
