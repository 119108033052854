import axios from 'axios'
import { IEducationPassHolder, EducationPassHolder } from '@/shared/models/educationPassHolder.model'
import resourceService from './resourceService'

class EducationPassHolderService {
  getAllEducationPassHoldersPaginated (query: any) {
    resourceService.getResourcePaginated(query, 'educationPassHolder')
  }

  getAllEducationPassHolders () {
    resourceService.getAllResources('educationPassHolder')
  }

  createEducationPassHolder (EducationPassHolder: IEducationPassHolder) {
    return resourceService.createResource(EducationPassHolder, 'educationPassHolder')
  }

  getEducationPassHolder (id: any): Promise<any> {
    return resourceService.getResource(id, 'educationPassHolder')
  }

  updateEducationPassHolder (EducationPassHolder: IEducationPassHolder) {
    return resourceService.updateResource(EducationPassHolder, 'educationPassHolder')
  }

  deleteEducationPassHolder (id: string) {
    return resourceService.deleteResource(id, 'educationPassHolder')
  }

  getEducationPassHolderImage (id: string) {
    return resourceService.getResourceImage(id, 'educationPassHolder')
  }

  getEstablishmentName (id: string) {
    return resourceService.getEstablishmentName(id)
  }

  checkEducationPassHolderNumber (establishmentId: string, checkEducationPassHolderNumber: string) {
    return axios.get(`/api/establishment/${establishmentId}/checkEducationPassHolderNumber/${checkEducationPassHolderNumber}`).catch((e) => {
      console.error('educationPassHolderName already exists', e)
      return e.response
    })
  }

  sendNotification (emails: string[], subject: string, message: string) {
    return axios.post('/api/sendNotification', { emails, subject, message }).catch((e) => {
      console.error('sendNotification() failed', e)
    })
  }

  async getByEstablishmentId (query: any) {
    resourceService.getByEstablishmentId('educationPassHolder', query)
  }

  async getEducationPassHolderCount () {
    return axios.get('/api/educationPassHolderCount').catch(e => {
      console.error('getEducationpassHolderCount() failed', e)
      return e.response
    })
  }
}

const educationPassHolderService = new EducationPassHolderService()
export default educationPassHolderService
