import axios from 'axios'
import { IUser, User, UserRole } from '@/shared/models/user.model'
import store from '../../store'
import router from '../../router'

class UserService {
  getMyself () {
    store.commit('authenticate')

    return axios
      .get('/api/myself')
      .then(response => {
        const account = response.data
        if (account) {
          store.commit('authenticated', account)
        } else {
          store.commit('logout')
          router.push('/login')
        }
      })
      .catch(e => {
        store.commit('logout')
        // console.error('getUser() failed', e)
      })
  }

  getAllUsersPaginated (query: any) {
    store.dispatch('userStore/getList', query)
  }

  get account () {
    return store.getters.account
  }

  userIsSystemAdmin () {
    if (this.account && this.account.roles.includes(UserRole.admin)) {
      return true
    }
    return false
  }

  createUser (user: IUser) {
    return axios.post('/api/user', user).catch(e => {
      console.error('createUser() failed', e)
    })
  }

  signupUser (user: IUser) {
    return axios.post('/api/user/signup', user).catch(e => {
      console.error('signup() failed', e)
    })
  }

  resetPasswordConfirm (token: string) {
    return axios.post(`/api/reset_password/${token}`).catch(e => {
      console.error('resetPassword() failed', e)
    })
  }

  getUser (id: any): Promise<IUser> {
    return new Promise((resolve, reject) => {
      axios.get(`/api/user/${id}`).then(result => {
        if (result.status === 200 && result.data) {
          const user: IUser = result.data
          resolve(user)
        } else {
          console.error(`User with id: ${id} not found`)
          resolve(new User())
        }
      })
    })
  }

  updateUser (user: IUser, oldPassword: string) {
    return axios.put(`/api/user/${user._id}`, { user: user, oldPassword: oldPassword }).catch(e => {
      return e.response.status
    })
  }

  deleteUser (id: string) {
    return axios.delete(`/api/user/${id}`).catch(e => {
      console.error('deleteUser() failed', e)
    })
  }

  checkVersion () {
    const VERSION = 'latestFetchedVersion'
    return axios
      .get('/api/version')
      .then(response => {
        if (response && response.data && response.data.version) {
          const currentVersion: any = response.data.version
          const latestFetchedVersion: any = localStorage.getItem(VERSION)

          if (latestFetchedVersion !== null) {
            if (latestFetchedVersion !== currentVersion) {
              localStorage.setItem(VERSION, currentVersion)
              location.reload()
            }
          } else {
            localStorage.setItem(VERSION, currentVersion)
          }
        }
      })
      .catch(e => {
        console.error('updateUser() failed', e)
      })
  }

  getUserImage (id: string) {
    return axios.get(`/api/file/${id}`).catch(e => {
      console.error('getUserImage() failed', e)
      return e.response
    })
  }

  checkIfUserWithEmailAlreadyExists (email: string) {
    return axios.get(`/api/checkemail/${email}`).catch(e => {
      console.error('User with that email already exists', e)
      return e.response
    })
  }

  getAllCoordinators () {
    const query = {
      l: 0,
      lo: 1,
      sf: undefined,
      so: 'desc',
      search: { search: 'coordinator' }
    }
    store.dispatch('userStore/getList', query)
  }

  getAllUsers () {
    const query = {
      l: 0,
      lo: 1,
      sf: undefined,
      so: 'desc'
    }
    store.dispatch('userStore/getList', query)
  }

  checkIfUsernameExists (username: string) {
    return axios.get(`/api/checkusername/${username}`).catch(e => {
      console.error('Username already exists', e)
      return e.response
    })
  }

  getUserCount () {
    return axios.get('/api/usercount').catch(e => {
      console.error('getUserCount() failed', e)
      return e.response
    })
  }
}

const userService = new UserService()
export default userService
