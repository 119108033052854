import axios from 'axios'

class PublicService {
  checkIfParticipantWithEmailAlreadyExists (value: string, emailString: string) {
    return axios.get(`/api/lecture/${emailString.substring(emailString.indexOf(',') + 1, emailString.length)}/checkemail/${value}`).catch(e => {
      console.error('checkIfParticipantWithEmailAlreadyExists() failed', e)
      return e.response
    })
  }
}

const publicService = new PublicService()
export default publicService
