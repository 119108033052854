import dayjs from 'dayjs'
import Vue from 'vue'

export const DATE_FORMAT = 'YYYY-MM-DD'

export function initFilters () {
  Vue.filter('formatDate', (value: any) => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT)
    }
    return ''
  })
  Vue.filter('formatDateForTable', (value: any) => {
    if (value) {
      return dayjs(value).format('DD.MM.YYYY')
    }
    return ''
  })
  Vue.filter('formatDateForReport', (value: any) => {
    if (value) {
      return dayjs(value).format('DD.MM.YYYY HH:mm')
    }
    return ''
  })
  // Vue.filter('fullNameOfUser', (user: IUser) => {
  //   if (user && user.metadata.firstName && user.metadata.lastName) {
  //     return user.metadata.firstName + ' ' + user.metadata.lastName
  //   }
  //   return ''
  // })
}
