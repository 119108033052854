export interface IEstablishment {
  _id?: string
  name?: string
  address?: string
  email?: string
  phone?: string
  contact?: string
  postalCode?: string
  city?: string
  authorized?: [string]
  createdAt?: Date
  updatedAt?: Date
}

export class Establishment implements IEstablishment {
  constructor (
    public _id?: string,
    public name?: string,
    public address?: string,
    public email?: string,
    public phone?: string,
    public contact?: string,
    public createdAt?: Date,
    public updatedAt?: Date,
    public uuid?: string,
    public postalCode?: string,
    public city?: string,
    public authorized?: [string]
  ) {
    this._id = _id
    // return this
  }
}
