import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import userService from '@/shared/services/userService'
import store from '@/store'
import authService from '@/shared/services/authService'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/views/PagesView.vue'),
    children: [
      {
        name: 'Homepage',
        path: '',
        component: () => import('@/views/pages/HomePage.vue')
      },
      {
        name: 'Vortragsliste',
        path: 'association/:id/lectures',
        component: () => import('@/views/pages/AssociationLectures.vue')
      },
      {
        name: 'Anmelden',
        path: 'association/:id/lectures/:lectureId/register',
        component: () => import('@/views/pages/Register.vue')
      },
      {
        name: 'Abmelden',
        path: 'participant/:uuid',
        component: () => import('@/views/pages/DeRegisterParticipant.vue')
      },
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/pages/Login.vue')
      },
      {
        name: 'Confirm password reset',
        path: 'confirm-password-reset/:resetId',
        component: () => import('@/views/pages/ConfirmPasswordReset.vue')
      }
    ]
  },
  {
    path: '/app',
    component: () => import('@/views/AppView.vue'),
    children: [
      {
        name: 'Startseite',
        path: '',
        component: () => import('@/views/app/Dashboard.vue')
      },
      {
        name: 'UserProfile',
        path: 'profile',
        component: () => import('@/views/app/UserProfile.vue')
      },
      {
        name: 'Passwort ändern',
        path: 'change-password',
        component: () => import('@/views/app/ChangePassword.vue')
      },
      {
        name: 'Wizards',
        path: 'wizards',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/Wizard.vue')
      },
      {
        name: 'Benutzer',
        path: 'users',
        meta: { authorities: ['admin'] },
        component: () => import('@/views/app/UsersTable.vue')
      },
      {
        name: 'Applikationseinstellungen',
        path: 'settings/app',
        component: () => import('@/views/app/AppSettings.vue')
      },
      {
        name: 'Einrichtungen',
        path: 'establishments',
        meta: { authorities: ['admin'] },
        component: () => import('@/views/app/EstablishmentsTable.vue')
      },
      {
        name: 'Verbunde',
        path: 'associations',
        meta: { authorities: ['admin'] },
        component: () => import('@/views/app/AssociationTable.vue')
      },
      {
        name: 'Angebotsinhalte',
        path: 'lectureContent',
        meta: { authorities: ['admin'] },
        component: () => import('@/views/app/LectureContentTable.vue')
      },
      {
        name: 'Angebotsinhalt',
        path: 'lectureContent/:id',
        meta: { authorities: ['admin'] },
        component: () => import('@/views/app/LectureContentView.vue')
      },
      {
        name: 'Teilnehmerpässe',
        path: 'educationPassHolder',
        meta: { authorities: ['admin', 'service', 'coordinator'] },
        component: () => import('@/views/app/EducationPassHolderTable.vue')
      },
      {
        name: 'Passwort zurücksetzen',
        path: 'reset-password',
        meta: { authorities: ['admin', 'service', 'coordinator'] },
        component: () => import('@/views/app/ResetPassword.vue')
      },
      {
        name: 'Termine',
        path: 'lecture',
        meta: { authorities: ['admin', 'service', 'coordinator'] },
        component: () => import('@/views/app/LectureTable.vue')
      },
      {
        name: 'Termin',
        path: 'lecture/:id',
        meta: { authorities: ['admin', 'service', 'coordinator'] },
        component: () => import('@/views/app/LectureView.vue')
      },
      {
        name: 'Nachricht senden',
        path: 'notify',
        meta: { authorities: ['admin', 'service', 'coordinator'] },
        component: () => import('@/views/app/Notification.vue')
      },
      {
        path: '*',
        component: () => import('@/views/shared/ErrorPage.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/PagesView.vue'),
    children: [
      {
        name: 'Fehlermeldung',
        path: '',
        component: () => import('@/views/shared/ErrorPage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/404')
  }

  await userService.getMyself()

  if (store.getters.authenticated) {
    if (to.path.includes('/login')) {
      router.push('/app')
    }
  }

  if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    authService.hasAnyRole(to.meta.authorities).then((value) => {
      if (!value) {
        if (authService.userIdentity) {
          next('/forbidden')
        } else {
          next('/login')
        }
      } else {
        next()
      }
    })
  } else {
    // no authorities, so just proceed
    next()
  }
})

export default router
