import Vue from 'vue'

const requireComponent = require.context('@/components/global', true, /\.vue$/)

for (const file of requireComponent.keys()) {
  const componentConfig = requireComponent(file)

  Vue.component(
    'App' + componentConfig.default.name,
    componentConfig.default || componentConfig
  )
}
