import Vue from 'vue'

const IsMobile = {
  install (Vue: { prototype: { screenSizeIsMobile: () => boolean } }) {
    Vue.prototype.screenSizeIsMobile = () => {
      // testing this showed, that those global functions might be executed only once
      // it takes a reload for those changes to take effect
      return screen.width < 800
    }
  }
}
Vue.use(IsMobile)

// 2. add a global asset
// Vue.directive('my-directive', {
//   bind (el, binding, vnode, oldVnode) {
//     // some logic ...
//   }
//   ...
// })

// 3. inject some component options
// Vue.mixin({
//   created: function () {
//     // some logic ...
//   }
//   ...
// })

// 4. add an instance method
// Vue.prototype.$myMethod = function (methodOptions) {
//   // some logic ...
// }
