<template>
  <app-material-card class="v-card--material-stats" v-bind="$attrs" v-on="$listeners">
    <div class="text-right" v-text="value" />

    <template #actions>
      <slot name="actions" />
    </template>

    <slot />
  </app-material-card>
</template>

<script>
export default {
  name: 'MaterialStatsCard',

  props: { value: String }
}
</script>

<style lang="sass">
.v-card--material-stats.v-card--material .v-card--material__title
  color: #999999
  font-size: .875rem !important
  margin-left: auto
  text-align: right

.v-card__subtitle--material-stats
  color: #3C4858
</style>
