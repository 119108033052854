import router from '@/router'
import store from '@/store'
import axios from 'axios'
// import store from './store'

class AuthService {
  login (data: any) {
    return axios.post('api/auth', data)
  }

  logout () {
    return axios
      .get('/api/logout')
      .then(response => {
        if (response.status === 200) {
          store.commit('logout')
        }
        router.push('/login')
      })
      .catch(e => {
        console.error('logout() failed', e)
      })
  }

  async hasAnyRole (authorities: any) {
    if (typeof authorities === 'string') {
      authorities = [authorities]
    }

    for (const authority of authorities) {
      if (
        this.userIdentity &&
        this.userIdentity.roles &&
        this.userIdentity.roles.includes(authority)
      ) {
        return Promise.resolve(true)
      }
    }

    return Promise.resolve(false)
  }

  recoverPassword (data: any) {
    return axios.post('/api/forgot_password', data).catch(e => {
      console.error('login() failed', e)
    })
  }

  get authenticated () {
    return store.getters.authenticated
  }

  get userIdentity () {
    return store.getters.account
  }
}

const authService = new AuthService()
export default authService
