import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@/styles/overrides.sass'

import VuetifyToast from 'vuetify-toast-snackbar'
import { VBtn, VIcon, VSnackbar } from 'vuetify/lib'

import en from 'vuetify/src/locale/en'
import de from 'vuetify/src/locale/de'

// following is required for vuetifyToast
// but maybe check if it destroys vuetifys treeshaking somehow
Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

const theme = {
  primary: '#2676A0',
  secondary: '#2676A0',
  accent: '#3CB950',
  info: '#00CAE3',
  success: '#5d9b61',
  warning: '#FFC107',
  error: '#D98674',
  updateButtonColor: '#b6d7e8'
}
// cool orange ;)
// primary: '#ff8b2f',

const VuetifyObj = new Vuetify({
  lang: {
    // t: (key, ...params) => i18n.t(key, params),
    locales: { de, en },
    current: 'de'
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
    // dark: true
  }
})

Vue.use(VuetifyToast, {
  $vuetify: VuetifyObj.framework,
  x: 'center',
  y: 'bottom',
  color: 'darkgrey',
  icon: 'mdi-alert-circle-outline',
  iconColor: '', // default
  classes: ['flex-snackbar'],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: true,
  closeText: '',
  closeIcon: 'mdi-check',
  closeColor: 'primary',
  slot: [], // default
  shorts: {
    error: {
      closeIcon: 'mdi-close',
      closeColor: 'error'
    }
  },
  property: '$toast' // default
})

export default VuetifyObj
