export enum UserRole {
  admin = 'admin',
  coordinator = 'coordinator',
  service = 'service'
}
export interface IUser {
  _id?: string
  name?: string
  password?: string
  generatedPassword?: string
  email?: string
  roles?: UserRole[]
  phone?: string
  activated?: boolean
  lastLogin?: Date
  lastActivity?: Date
  createdAt?: Date
  updatedAt?: Date
  establishments?: string[]
}

export class User implements IUser {
  constructor (
    public _id?: string,
    public username?: string,
    public password?: string,
    public generatedPassword?: string,
    public email?: string,
    public roles?: UserRole[],
    public phone?: string,
    public activated?: boolean,
    public lastLogin?: Date,
    public lastActivity?: Date,
    public createdAt?: Date,
    public updatedAt?: Date,
    public uuid?: string,
    public establishments?: string[]
  ) {
    this.roles = [UserRole.service]
    // return this
  }
}
