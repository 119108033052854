import { localize } from 'vee-validate'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import validationMessages from 'vee-validate/dist/locale/de.json'
import validationMessagesEN from 'vee-validate/dist/locale/en.json'

import en from 'vuetify/src/locale/en'
import de from 'vuetify/src/locale/de'
import { Framework } from 'vuetify'

Vue.use(VueI18n)
localize('de', validationMessages)

export function changeLanguage (lang: string, vuetify: Framework | null = null) {
  i18n.locale = lang
  if (vuetify) {
    vuetify.lang.current = lang
  }
  if (lang === 'de') {
    localize('de', validationMessages)
  } else if (lang === 'en') {
    localize('de', validationMessagesEN)
  }
}

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en
  },
  de: {
    ...require('@/locales/de.json'),
    $vuetify: de
  }
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: messages
})

export default i18n
