/* eslint-disable prefer-regex-literals */

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

// copied from https://codesandbox.io/s/n9lrp9mx2p?file=/src/App.vue
export function debounceInput (debounce: number | undefined, callback: any, timeout = 400) {
  clearTimeout(debounce)
  return setTimeout(() => {
    callback()
  }, timeout)
}

// copied from https://gist.github.com/davalapar/d0a5ba7cce4bc599f54800da22926da2
export function downloadFile (data: any, filename: any, mime: any) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], {
    type: mime || 'application/octet-stream'
  })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
    return
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename)
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

export function downloadFileURL (url: string, filename: string, mime: string) {
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = url
  tempLink.setAttribute('download', filename)
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url)
  }, 100)
}

export function screenSizeIsMobile () {
  return screen.width < 800
}

export function isMobileScreenWidth () {
  return window.innerWidth < 800
}

export function regexEUNumber (value: string) {
  const decimals = '*'
  const separator = ','
  if (value === null || value === undefined || value === '') {
    return {
      valid: false
    }
  }
  if (Number(decimals) === 0) {
    return {
      valid: /^-?\d*$/.test(value)
    }
  }
  const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
  const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

  return regex.test(value)
}

export function regexNumbersAndSpecialChars (value: string) {
  if (value === null || value === undefined || value === '') {
    return {
      valid: false
    }
  }
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp('^[0-9-/]*$')

  return regex.test(value)
}

export function copyToClipboard (value: string): boolean {
  /* Copy the text inside the text field */
  navigator.clipboard.writeText(value)

  /* Alert the copied text */
  // alert('Copied the text: ' + value)
  return true
}
